.container {
  margin: 0 40px 0 20px;
  display: flex;
  align-items: center;
}

.userLetter {
  background-color: #ffffff;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Montserrat", sans-serif;
  font-size: 12px;
  color: #555555;
  box-shadow: 0 2px 2px 0 rgba(29, 29, 27, 0.1);
}
.userName {
  margin-left: 10px;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #000;
  display: none;
}

@media screen and (min-width: 768px) {
  .container {
    margin-left: auto;
    margin-right: 30px;
  }
  .userLetter {
    font-size: 13px;
  }
  .userName {
    display: block;
  }
}
@media screen and (min-width: 1280px) {
  .userLetter {
    font-size: 13px;
  }
  .container {
    margin: 0 40px 0 70px;
  }
}
