.mainNav {
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  width: 100vw;
  height: 100vh;
}
.mainNav ul {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  list-style: none;
  margin: 0 0 0 0;
}
.link {
  display: inline-block;
  text-decoration: none;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #000;
  position: relative;
  transition: color ease-in-out 0.15s;
}
.link:hover {
  color: #ff6b09;
  transition: color ease-in-out 0.15s;
}

.activeLink::after {
  content: "";
  display: block;
  position: absolute;
  bottom: -3px;
  left: 0;
  width: 100%;
  height: 1.1px;
  background: #ff6b09;
}
.mainNav ul li:not(:last-child) {
  margin-right: 40px;
}
.headerBtn {
  border: none;
  padding: 20px;
  background-color: inherit;
}
.logoutLogo {
  height: 14px;
}
.logoutIcon {
  position: relative;
  border: none;
  outline: none;
  background-color: transparent;
  cursor: pointer;
  transition: transform 0.4s linear;
}

.logoutIcon:hover {
  transform: scale(1.2);
}
.logout1 {
  fill: black;
  width: 12px;
  height: 16px;
}
.logout2 {
  fill: black;
  width: 9px;
  height: 6px;
  position: relative;
  left: -5px;
  top: -5px;
}

.arrow {
  position: relative;
  left: 1px;
}
.posCenter {
  display: block;
  margin: 26px auto 0;
}

@media screen and (max-width: 767px) {
  .mainNav ul {
    padding: 0;
    display: block;
    text-align: center;
    width: 100vw;
  }

  .mainNav ul li {
    border-bottom: 1px solid #e2e3e5;
    padding: 26px 0;
    display: block;
  }
  .mainNav ul li:not(:last-child) {
    margin-right: 0;
  }
}
@media screen and (min-width: 768px) {
  .posCenter {
    display: none;
  }
  .mainNav {
    height: 100%;
    width: 100%;
  }
  .mainNav ul {
    max-width: 345px;
    display: flex;
    justify-content: space-between;
  }
}
@media screen and (min-width: 1280px) {
  .mainNav {
    position: static;
  }
  .mainNav ul {
    max-width: 400px;
  }

  .mainNav ul li:not(:last-child) {
    margin-right: 85px;
  }
}
