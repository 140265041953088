// @import "./fonts/fonts.scss";
@import "./styles/stylesheet.scss";

body {
  margin: 0;
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f5f6fb;
}

code {
  font-family: sans-serif, source-code-pro, Menlo, Monaco, Consolas,
    "Courier New", monospace;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-size: 100%;
}

a {
  text-decoration: none;
}
