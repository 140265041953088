/* This stylesheet generated by Transfonter (https://transfonter.org) on October 3, 2017 10:05 PM */

@font-face {
  font-family: "Montserrat";
  src: local("Montserrat Regular"), local("Montserrat-Regular"),
    url("../fonts/Montserrat/Montserrat-Regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Montserrat";
  src: local("Montserrat Medium"), local("Montserrat-Medium"),
    url("../fonts/Montserrat/Montserrat-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Montserrat";
  src: local("Montserrat Bold"), local("Montserrat-Bold"),
    url("../fonts/Montserrat/Montserrat-Bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Montserrat";
  src: local("Montserrat ExtraBold"), local("Montserrat-ExtraBold"),
    url("../fonts/Montserrat/Montserrat-ExtraBold.woff") format("woff");
  font-weight: 800;
  font-style: normal;
}
